<template>
  <div>
    <div class="header-slot">
      <div class="align-left-content">
        <div class="go-back-container" @click="goBack">
          <svg-icon icon-class="back"></svg-icon>
          <span>返回上一步</span>
        </div>

        <div class="app-detail-header">
          <el-image :src="detailInfo.icon" class="app-detail-icon" v-if="!loading"> </el-image>

          <div>
            <div class="app-store-title">{{ name }} ({{ version }})</div>
            <div class="app-store-description">{{ detailInfo.description }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="app-detail-content" v-loading="loading">
      <div class="version-list">
        <div class="resource-title">版本</div>

        <div class="version-item" v-for="(item, index) in versionList" :key="index">
          <div class="title" style="font-size: 14px;" v-if="version == item.version">{{ item.version }}</div>
          <a href="javascript:;" @click="toVersion(item.version)" v-else> {{ item.version }}</a>

          <div class="name-value">
            <span class="name">创建时间：</span>
            <span class="value"> {{ moment(item.created).format("YYYY-MM-DD HH:mm:ss ") }}</span>
          </div>
        </div>
      </div>

      <div class="detail-sider">
        <div class="resource-title">基本信息</div>
        <div class="name-value">
          <div class="name">创建时间</div>
          <div class="value">
            {{ moment(detailInfo.created).format("YYYY-MM-DD HH:mm:ss ") }}
          </div>
        </div>

        <div class="name-value">
          <div class="name">主页</div>
          <div class="value">
            <a :href="detailInfo.home" target="_blank">{{ detailInfo.home }}</a>
          </div>
        </div>

        <div class="name-value">
          <div class="name">App Version</div>
          <div class="value">
            {{ detailInfo.appVersion }}
          </div>
        </div>

        <div class="name-value">
          <div class="name">Api Version</div>
          <div class="value">
            {{ detailInfo.apiVersion }}
          </div>
        </div>

        <div class="name-value">
          <div class="name">Digest</div>
          <div class="value">
            {{ detailInfo.digest }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chartVersions, chartVersionDescribe } from "api/appstore";

export default {
  data() {
    return {
      detailInfo: {},
      versionList: [],
      loading: false
    };
  },

  methods: {
    getChartVersion(name) {
      chartVersions(name).then(response => {
        if (response) {
          this.versionList = response;
        }
        console.log(response);
      });
    },

    getChartVersionDescribe(name, version) {
      this.loading = true;

      chartVersionDescribe(name, version).then(response => {
        this.loading = false;
        if (response) {
          this.detailInfo = response;
        }
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    toVersion(version) {
      this.$router.push({
        path: `/appstore/detail/${this.name}/${version}`
      });
    }
  },

  mounted() {
    this.getChartVersionDescribe(this.name, this.version);
    this.getChartVersion(this.name);
  },

  computed: {
    name() {
      return this.$route.params.name;
    },

    version() {
      return this.$route.params.version;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.go-back-container {
  @include flex(flex-start);

  cursor: pointer;
  margin-bottom: 10px;
  font-size: 12px;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.app-detail-header {
  @include flex(flex-start);
  flex-wrap: nowrap;

  .app-detail-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .app-store-description {
    max-height: 60px;
    overflow: hidden;
  }
}

.app-detail-content {
  width: calc(80% + 40px);
  margin: 0 auto;
  @include flex(space-between, flex-start);
  flex-wrap: nowrap;
}

.version-list {
  .version-item {
    width: 500px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid $border-color;
    transition: 0.3s all ease;
  }

  .name-value {
    line-height: 24px;
  }
}
</style>
